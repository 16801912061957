import { HttpParams } from '@angular/common/http';
import { ApiService } from '@app/services/api/api.service';

export class Utils {
  /**
   * @description
   *
   * Build query params based on given source object which its type is parameterized on `T`
   *
   * @param source Source to build {@link HttpParams} from it
   * @param notWorkspaceBased Indicates whether or not we should be remove workspace filter
   *
   * @returns HttpParams built based on given source
   */
  static buildParams<T>(source: T, notWorkspaceBased?: boolean): HttpParams {
    /**
     * @description
     *
     * An HTTP request/response body that represents serialized parameters,
     * per the MIME type `application/x-www-form-urlencoded`.
     *
     * @see HttpParams
     */
    let httpParams: HttpParams = new HttpParams();
    Object.keys(source as any).forEach((key: string): void => {
      /**
       * @description
       *
       * Value of the key
       */
      const value: string | number | boolean | Date = (source as any)[key];
      /**
       * If value actually had value, then append it to http params
       */
      if (typeof value !== 'undefined' && value !== null && value !== '') {
        httpParams = httpParams.append(key, value.toString());
      }
    });
    /**
     * Check if whether or not we should be remove workspace filter
     */
    if (notWorkspaceBased) {
      httpParams = httpParams.append(
        ApiService.WORKSPACE_NOT_REQUIRED_PARAM,
        'true'
      );
    }
    return httpParams;
  }

  /**
   * @description
   *
   * Insert given text at current caret position in the given input element
   *
   * @param inputElement Input element to insert to
   * @param text Text to insert
   * @param isCoded Indicates whether to wrap text into a hardcoded brackets or not
   */
  static insertIntoInput<T extends HTMLElement>(
    inputElement: T,
    text: string,
    isCoded?: boolean
  ): void {
    /**
     * Wrap given text into hardcoded brackets
     */
    if (isCoded) {
      text = `{{${text}}}`;
    }
    /**
     * Focus on element
     */
    setTimeout((): void => {
      inputElement.focus();
      /**
       * @description
       *
       * Executes 'insertText' command current selection, or the given range.
       */
      document.execCommand('insertText', false, text);
    });
  }
}

export const TIMEZONES: {[timezoneInitials: string]: { offset1: string; offset2: string };}  = {
  PST: {
    offset1: '-08:00',
    offset2: '-07:00'
  },
  MST: {
    offset1: '-07:00',
    offset2: '-06:00'
  },
  CST: {
    offset1: '-06:00',
    offset2: '-05:00'
  },
  EST: {
    offset1: '-05:00',
    offset2: '-04:00'
  },
  HST: {
    offset1: '-10:00',
    offset2: '-10:00'
  },
  AKST: {
    offset1: '-09:00',
    offset2: '-08:00'
  }
};

export function getUTCDateObjFromBrowserDate(currentTimezoneDateTime: Date, orgTimezone: string) {
  const datlightOffset = getDaylightOffset(
    currentTimezoneDateTime,
    TIMEZONES[orgTimezone]
  );

  const monthStr: string = (currentTimezoneDateTime.getMonth() + 1)
    .toString()
    .padStart(2, '0');

  const dateTimeStr: string =
    currentTimezoneDateTime.getFullYear().toString() +
    '-' +
    monthStr +
    '-' +
    currentTimezoneDateTime.getDate().toString().padStart(2, '0') +
    'T' +
    currentTimezoneDateTime.getHours().toString().padStart(2, '0') +
    ':' +
    currentTimezoneDateTime.getMinutes().toString().padStart(2, '0') +
    ':' +
    currentTimezoneDateTime.getSeconds().toString().padStart(2, '0') +
    '.000' +
    datlightOffset;

  const selectedTimezoneDate = new Date(dateTimeStr);

  const selectedDateUTC = Date.UTC(
    selectedTimezoneDate.getUTCFullYear(),
    selectedTimezoneDate.getUTCMonth(),
    selectedTimezoneDate.getUTCDate(),
    selectedTimezoneDate.getUTCHours(),
    selectedTimezoneDate.getUTCMinutes(),
    selectedTimezoneDate.getUTCSeconds()
  );

  const dateTimeObj = new Date(selectedDateUTC); // UTC Date Object

  return dateTimeObj
}

export function getTimezoneName(currentTimezoneDateTime: Date,  orgTimezone: string): string {
  const timezones: {[timezoneInitials: string]: { offset1: string; offset2: string };}  = {
    PST: {
      offset1: 'PST',
      offset2: 'PDT'
    },
    MST: {
      offset1: 'MST',
      offset2: 'MDT'
    },
    CST: {
      offset1: 'CST',
      offset2: 'CDT'
    },
    EST: {
      offset1: 'EST',
      offset2: 'EDT'
    },
    HST: {
      offset1: 'HST',
      offset2: 'HDT'
    },
    AKST: {
      offset1: 'AKST',
      offset2: 'AKDT'
    }
  };

  let timezoneName = 'PST';

  const datlightOffset = getDaylightOffsetKey(currentTimezoneDateTime);

  if (orgTimezone in timezones) {
    timezoneName = timezones[orgTimezone][datlightOffset];
  }

  return timezoneName
}

function getDaylightOffset(
  scheduledDateWithoutTimeZone: Date,
  timezoneOffsets: { offset1: string; offset2: string }
): string {
  const offsetKey = getDaylightOffsetKey(scheduledDateWithoutTimeZone);
  return timezoneOffsets[offsetKey];
}

function getDaylightOffsetKey(scheduledDateWithoutTimeZone: Date): 'offset1' | 'offset2' {
  // In US daylight saving changes on 2ND Sunday of March and 1ST Sunday of November
  const currentYear = new Date().getFullYear();
  const secondSundayOfMarch = calculateSecondSundayOfMonth(currentYear, 2);
  const firstSundayOfNovember = calculateFirstSundayOfMonth(currentYear, 10);

  let offsetKey: 'offset1' | 'offset2' = 'offset1';
  if (
    scheduledDateWithoutTimeZone >= secondSundayOfMarch &&
    scheduledDateWithoutTimeZone < firstSundayOfNovember
  ) {
    offsetKey = 'offset2';
  }

  return offsetKey;
}

function calculateFirstSundayOfMonth(year: number, month: number) {
  // Month is numbered from 0-11 (Jan-Dec)
  const d = new Date(year, month, 1, 2, 0, 0);
  d.setDate(d.getDate() + 7 - d.getDay());
  return d;
}

function calculateSecondSundayOfMonth(year: number, month: number) {
  // Month is numbered from 0-11 (Jan-Dec)
  const d = new Date(year, month, 1, 2, 0, 0);
  d.setDate(d.getDate() + 7 + 7 - d.getDay());
  return d;
}
