import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { WorkspaceService } from '../workspace/workspace.service';
import { UserService } from '@app/services/user/user.service';
import { Org } from '@app/interfaces/org';
import { User } from '@app/interfaces/user';
import { AuthToken } from '@app/interfaces/auth-token';
import { AuthResponse, MFAResponse } from '@app/interfaces/auth-response';
import { UserWorkspace } from '@app/interfaces/user-workspace';
import { ParamsObject } from '@app/interfaces/params-object';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private router: Router,
    private cookieService: CookieService,
    private workspaceService: WorkspaceService
  ) {
    // If user is authenticated, then update user subject
    if (this.isAuth) {
      const storedUserData: string = localStorage.getItem('user') as string;
      if (
        storedUserData &&
        JSON.parse(storedUserData).workspaces &&
        JSON.parse(storedUserData).workspaces.length
      ) {
        UserService.user = JSON.parse(
          localStorage.getItem('user') as string
        ) as User;
      } else {
        this.unAuthUser();
      }
    }
  }

  /**
   * Parse token from token.
   *
   * @param token Authentication token.
   *
   * @return Parsed token.
   */
  private static parseJwt(token: string): AuthToken | null {
    const base64Url = token.split('.')[1];
    if (typeof base64Url === 'undefined') {
      return null;
    }
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  }

  /**
   * Save/update token to cookies
   *
   * @param token Authentication token
   */
  setToken(token: string): void {
    const parsedJwt: AuthToken = AuthService.parseJwt(token) as AuthToken;
    if (parsedJwt) {
      this.cookieService.set(
        'token',
        token,
        new Date(parsedJwt.exp * 1000),
        '/'
      );
    }
  }

  /**
   * @return Is user authenticated or not
   */
  get isAuth(): boolean {
    return this.cookieService.check('token');
  }

  /**
   * Un-authenticate user by cleaning cookies
   */
  public unAuthUser(): void {
    this.cookieService.deleteAll('/');
    UserService.user = null as any;
    localStorage.clear();
    // Redirect user to login page.
    this.router.navigateByUrl('/login');
  }

  login(email: string, password: string): Observable<User | string> {
    return this.http
      .post<AuthResponse | MFAResponse >(this.apiService.api.v1 + 'token', { email, password })
      .pipe(
        map((data: AuthResponse | MFAResponse): User | string => {
          if ((data as MFAResponse).salt !== undefined) { // MFA is enabled
            return (data as MFAResponse).salt;
          }
          else { // MFA is not enabled
            return this.mfaSuccess(data as AuthResponse);
          }
        })
      );
  }

  mfaVerify(otp: string, email: string, salt: string): Observable<User> {
    // Setup Parameters
    let httpParams = new HttpParams();

    httpParams = httpParams.append('otp', otp);
    httpParams = httpParams.append('email', email);
    httpParams = httpParams.append('salt', salt);
    
    return this.http
      .get<AuthResponse>(this.apiService.api.v1 + 'token/verify', { params: httpParams })
      .pipe(
        map((data: AuthResponse): User => {
          return this.mfaSuccess(data as AuthResponse);
        })
      );
  }

  mfaSuccess(data: AuthResponse): User {
    const currentWorkspaceId = this.workspaceService.currentWorkspace ? this.workspaceService.currentWorkspace.id : null;
    
    // Store user into localstorage
    this.setToken(data.token);
    UserService.user = data.user;

    // Setting the User's Workspace    
    if (
      !this.workspaceService.currentWorkspace ||
      (this.workspaceService.currentWorkspace &&
        !data.user.workspaces.some(
          (workspace: UserWorkspace): boolean =>
            currentWorkspaceId === workspace.id
        ))
    ) {

      // Default workspace to set after login.
      // Find default workspace by their `primary` property's value.
      let defaultWorkspace: UserWorkspace = data.user.workspaces.find(
        (workspace: UserWorkspace): boolean => workspace.primary
      ) as UserWorkspace;

      // If default workspace was not found, set first workspace in the list as default.
      if (!defaultWorkspace) {
        defaultWorkspace = data.user.workspaces[0];
      }

      // Set current workspace
      this.workspaceService.currentWorkspace = defaultWorkspace;
    }

    return data.user;
  }

  /**
   * Register user by given payload
   *
   * @param payload Object map of the HTTP params
   */
  register(payload: ParamsObject): Observable<any> {
    return this.http.post(
      this.apiService.api.v1 + 'auth/registration/',
      payload
    );
  }

  /**
   * Register user by given payload
   *
   * @param payload Object map of the HTTP params
   */
  inactive_user(payload: ParamsObject): Observable<any> {
    return this.http.post(this.apiService.api.v1 + 'inactive', payload);
  }

  /**
   * Send an email regarding reset password link to user by given email address
   *
   * @param email User email
   */
  forgotPassword(email: string): Observable<any> {
    return this.http.post(this.apiService.api.v1 + 'auth/password/reset/', {
      email
    });
  }

  /**
   * Reset user's password by email link
   *
   * @param payload Object map of the HTTP params
   */
  forgotPasswordVerification(payload: ParamsObject): Observable<void> {
    return this.http.post<void>(
      this.apiService.api.v1 + 'auth/password/reset/confirm/',
      payload
    );
  }

  /**
   * Reset user's password by given payload
   *
   * @param payload Object map of the HTTP params
   */
  resetPassword(payload: ParamsObject): Observable<object> {
    return this.http.post(
      this.apiService.api.v1 + 'auth/password/change/',
      payload
    );
  }

  /**
   * @description
   *
   * Update specific ORG information property
   *
   * @param properties Properties to update
   */
  patchProperty(properties: Partial<Org>): Observable<Org> {
    return this.http.patch<Org>(
      `${this.apiService.api.v1}orgs/${UserService.user.org.id}`,
      properties
    );
  }
}
